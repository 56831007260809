<template>
	<section class="section wow">
		<div class="content">
			<div class="div" />
			<div class="signup-social-buttons">
				<div class="text sm dark">
					<p>Войти с помощью:</p>
				</div>
				<button
					class="button-hollow icon-hover"
					@click="loginExternal('yandex')"
				>
					<div class="icon yandex" />
				</button>
				<button
					class="button-hollow icon-hover"
					@click="loginExternal('mailru')"
				>
					<div class="icon mailru" />
				</button>
				<button
					class="button-hollow icon-hover"
					@click="loginExternal('vk')"
				>
					<div class="icon vk" />
				</button>
				<button
					class="button-hollow icon-hover"
					@click="loginExternal('facebook')"
				>
					<div class="icon facebook" />
				</button>
				<button
					class="button-hollow icon-hover"
					@click="loginExternal('google')"
				>
					<div class="icon google" />
				</button>
			</div>
			<!--			<div class="field-wrap">-->
			<!--				<label class="field-label">-->
			<!--					<c-select-->
			<!--						:name="'Город'"-->
			<!--						autosearch-city-->
			<!--						:is-clear="isClearCity"-->
			<!--						@selected="selectCity"-->
			<!--						@cleared="() => (isClearCity = false)"-->
			<!--					/>-->
			<!--				</label>-->
			<!--			</div>-->
			<!--			<div class="field-wrap">-->
			<!--				<c-select-->
			<!--					:options="EDU"-->
			<!--					:name="'Образование:'"-->
			<!--					:default="educationName"-->
			<!--					@selected="selectEducation"-->
			<!--				/>-->
			<!--			</div>-->
			<!--			<div class="field-wrap">-->
			<!--				<c-select-->
			<!--					:options="employmentOptions"-->
			<!--					:name="'Занятость:'"-->
			<!--				/>-->
			<!--			</div>-->
			<!--			<div class="field-wrap">-->
			<!--				<c-select-->
			<!--					:options="responseStatusesOptions"-->
			<!--					:name="'Статусы откликов:'"-->
			<!--				/>-->
			<!--			</div>-->
			<div class="field-wrap">
				<!--				<c-select-->
				<!--					:options="vacancyStatusesOptions"-->
				<!--					:name="'Статусы вакансий:'"-->
				<!--				/>-->
			</div>
			<div class="field-wrap">
				<button
					class="button"
					@click="resetCity"
				>
					Reset
				</button>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model="salary"
						v-mask="mask"
						type="text"
						class="field"
						placeholder="Зарплата"
						@input="inputSalary($event)"
						@keydown="keyDownSalary($event)"
					>
					<div class="label">Зарплата</div>
				</label>
			</div>
			<div class="container">
				<h2>Кнопки</h2>
				<BaseButton orange>
					Кнопка
				</BaseButton>
				<BaseButton blue>
					Кнопка
				</BaseButton>
				<BaseButton
					transparent
					favorite
				>
					В избранное
				</BaseButton>
				<BaseButton
					transparent
					favorite
					favorite-active
				>
					В избранное
				</BaseButton>
				<BaseButton
					transparent
					favorite
					favorite-active
				/>
				<BaseButton
					class="button-save"
					light-border
					transparent
				>
					<div class="button-save__icon" />
					<span>Скачать</span>
				</BaseButton>
				<BaseButton
					class="button-download"
					transparent
				>
					<img
						class="button-download__icon"
						src="/img/icons/icon-pdf-2.svg"
						alt=""
					>
					<span>Скачать</span>
				</BaseButton>
				<BaseButton
					class="button-save"
					white-border
					transparent
				>
					Начать бесплатно
				</BaseButton>
				<h2>
					Элементы формы
				</h2>
				<BaseInput
					v-model="name"
					:@input="setName(name)"
				/>
				<!-- <BaseInput not-label is-white placeholder="Имя" :@input="setName(name)" v-model="name"/> -->
				<BaseTextArea
					v-model="info"
					placeholder="О себе"
				/>
				<h2>Чекбоксы</h2>
				<div class="container-checkboxs">
					<BaseCheckbox
						:id="1"
						:value="isChecked"
					/>
					<BaseCheckbox
						:id="2"
						is-square
					/>
				</div>
				<h2>Карточка Вакансий</h2>
				<BaseCardVacancies :vacancy="vacancy" />
				<BaseCardVacancies
					:vacancy="vacancy"
					is-favorite-page
				/>
				<BaseCardVacancies
					:vacancy="vacancy"
					is-position-sidebar
				/>
				<h2>Социальные сети</h2>
				<BaseSocial class="container-soial" />
				<BaseSocial
					class="container-soial"
					white
				/>
				<BaseCardCourseSlider
					:course="popularCourse"
					:show-button="true"
				/>
			</div>
			<BaseSidebar
				:filters="filters"
				:filter-sections="filterSections"
				:filter-sub-sections="filterSubSections"
				@changeParametersFilters="changeFilters($event)"
				@clearFilter="clearFilter($event)"
			/>
		</div>
	</section>
</template>

<script>
import {mapActions} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import BaseButton from '../../components/BaseComponents/BaseButton.vue';
import BaseInput from '../../components/BaseComponents/BaseInput.vue';
import BaseSocial from '../../components/BaseComponents/BaseSocial.vue';
import BaseTextArea from '../../components/BaseComponents/BaseTextArea.vue';
import BaseCheckbox from '../../components/BaseComponents/BaseCheckbox.vue';
import BaseCardVacancies from '../../components/BaseComponents/BaseCardVacancies.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import BaseCardCourseSlider from '../../components/BaseComponents/BaseCardCourseSlider';
import BaseSidebar from '@/components/BaseComponents/BaseSidebar/BaseSidebar';

const numberMask = createNumberMask({
    thousandsSeparatorSymbol: ',',
    prefix: '',
    suffix: ' ₽',
    decimalLimit: 0
});

export default {
    components: {
        BaseButton,
        BaseInput,
        BaseTextArea,
        BaseCheckbox,
        BaseSocial,
        BaseCardVacancies,
        // BaseIcon,
        BaseCardCourseSlider,
        BaseSidebar,
    },
    data: () => ({
        name: 'Иван',
        info: 'Я вот такой',
        vacancy: {
            id: 2,
            name: 'Амбассадоры «Лифта в будущее»',
            company: {
                id: 2,
                company_name: 'МАКС-2021',
                in_main_page: true,
                company_real_name: null,
                city: 'Москва',
                phone: null,
                email: null
            },
            vacancy_owner: [''],
            city: 'Магадан',
            street: null,
            house: null,
            apartment: null,
            section_internships: [],
            duties:
                'Миссия амбассадоров – делиться полученными знаниями и опытом, рассказывать студентам и школьникам о возможностях для быстрого старта в профессиях и важнейших навыках современного человека.\r\nОбучение начнется в апреле 2021 года, в летний период пройдут стажировки, а с сентября по декабрь амбассадоры будут наставниками школьных команд в работе над кейсами от активов АФК «Система», лучшие команды и наставники получат призы.',
            requirements:
                'Миссия амбассадоров – делиться полученными знаниями и опытом, рассказывать студентам и школьникам о возможностях для быстрого старта в профессиях и важнейших навыках современного человека.\r\nОбучение начнется в апреле 2021 года, в летний период пройдут стажировки, а с сентября по декабрь амбассадоры будут наставниками школьных команд в работе над кейсами от активов АФК «Система», лучшие команды и наставники получат призы.',
            conditions:
                'Миссия амбассадоров – делиться полученными знаниями и опытом, рассказывать студентам и школьникам о возможностях для быстрого старта в профессиях и важнейших навыках современного человека.\r\nОбучение начнется в апреле 2021 года, в летний период пройдут стажировки, а с сентября по декабрь амбассадоры будут наставниками школьных команд в работе над кейсами от активов АФК «Система», лучшие команды и наставники получат призы.',
            description:
                'Миссия амбассадоров – делиться полученными знаниями и опытом, рассказывать студентам и школьникам о возможностях для быстрого старта в профессиях и важнейших навыках современного человека.\r\nОбучение начнется в апреле 2021 года, в летний период пройдут стажировки, а с сентября по декабрь амбассадоры будут наставниками школьных команд в работе над кейсами от активов АФК «Система», лучшие команды и наставники получат призы.',
            type_employment: 'part_time_employment',
            type_employments: null,
            salary_from: 100,
            salary_before: 400,
            address: null,
            specialisation: [
                {id: 2, count: 4, remove: false, name: 'Домашний персонал'}
            ],
            field_activity: [
                {id: 2, count: 2, remove: false, name: 'Искусство, культура'}
            ],
            req_vacancy: [
                {
                    user: 36,
                    vacancy: 2,
                    cover_letter: null,
                    summary: null,
                    status: 'new',
                    comment: null,
                    favourite: false,
                    created: '2021-08-20T17:36:59.934858+03:00'
                },
                {
                    user: 4,
                    vacancy: 2,
                    cover_letter: 'wwwwwwwwwwwwwwwwwwww',
                    summary: null,
                    status: 'new',
                    comment: null,
                    favourite: false,
                    created: '2021-08-23T15:52:47.784618+03:00'
                },
                {
                    user: 11,
                    vacancy: 2,
                    cover_letter: null,
                    summary: null,
                    status: 'new',
                    comment: null,
                    favourite: false,
                    created: '2021-09-01T18:54:48.335172+03:00'
                },
                {
                    user: 8,
                    vacancy: 2,
                    cover_letter: 'test',
                    summary: null,
                    status: 'new',
                    comment: null,
                    favourite: false,
                    created: '2021-08-20T11:58:28.155800+03:00'
                }
            ],
            date_create: '2021-08-05T13:35:07.734959+03:00',
            modified: '2021-08-05T13:35:07.734986+03:00',
            status: 'actual'
        },
        city: 'Москва',
        isChecked: true,
        isClearCity: false,
        options: {
            years: []
        },
        mask: numberMask,
        salary: '',
        popularCourse: {
            id: 50,
            name: 'тестовый',
            slug: 'testovyj',
            section: [
                {
                    id: 2,
                    name: 'Lifestyle',
                    slug: 'lifestyle',
                    section_image:
                        'http://lift.teachbasetest.ru/media/course_section/None/images/2021-08-05_12-00-56.jpg',
                    in_main_page: true,
                    information_course: {
                        count: 6,
                        data: [
                            {id: 50, name: 'тестовый'},
                            {
                                id: 10,
                                name: 'Как общаться с людьми на работе?'
                            },
                            {
                                id: 12,
                                name:
                                    'Стресс-менеджмент: возьми под контроль свои нервы'
                            },
                            {
                                id: 20,
                                name:
                                    'Как контролировать сотрудников и не перегнуть палку?'
                            },
                            {
                                id: 21,
                                name: 'Как работать с MS Outlook: 10 приемов'
                            },
                            {
                                id: 25,
                                name: 'Финансы для нефинансистов'
                            }
                        ]
                    }
                }
            ],
            topic: [],
            course_sessions: [],
            students_enrolled: 4,
            recommended_books: [],
            experts: [],
            partners: [],
            category: 'course',
            titles: null,
            for_whom: null,
            description: null,
            materials: 2,
            tasks: 0,
            date_start: null,
            date_end: null,
            duration: 60,
            out_session_id: 0,
            out_course_id: 115335,
            out_url_view: null,
            priority: 0,
            tags: [],
            active: true,
            date_update: '2021-09-23T18:20:48.510246+03:00',
            date_create: '2021-09-14T17:14:33.963082+03:00',
            image_course: [
                {
                    id: 96,
                    user: null,
                    expert: null,
                    partner: null,
                    course: 50,
                    company: null,
                    video: null,
                    image: null,
                    type: 'image_course'
                },
                {
                    id: 94,
                    user: null,
                    expert: null,
                    partner: null,
                    course: 50,
                    company: null,
                    video: null,
                    image:
                        'http://lift.teachbasetest.ru/media/course_cover/50/images/2021-09-14_18-50-40.jpeg',
                    type: 'course_cover'
                }
            ]
        },
        educationName: '',
        filters: {
            'amount': 14,
            'isShow': true,
            'sections': {'value': [], 'isShow': true, 'isShowAll': false},
            'duration': {
                'is': true,
                'value': [100, 1500],
                'min': 100,
                'max': 1500,
                'mask': {'_custom': {'type': 'function', 'display': '<span>ƒ</span> e()'}},
                'isShow': false
            },
            'subsections': {'value': [], 'isShow': false, 'isShowAll': false},
            'types': {'value': [], 'isShow': false, 'isShowAll': false}
        },
        filterSections: [{
            'id': 16,
            'name': 'Digital',
            'slug': 'digital',
            'section_image': 'http://lift.teachbasetest.ru/media/course_section/None/images/2021-10-12_13-27-28.jpg',
            'in_main_page': true,
            'information_course': {'count': 1, 'data': [{'id': 164, 'name': 'Excel. Продвинутый'}]}
        }, {
            'id': 14,
            'name': 'Lifestyle',
            'slug': 'lifestyle',
            'section_image': 'http://lift.teachbasetest.ru/media/course_section/None/images/2021-10-12_13-27-01.jpg',
            'in_main_page': true,
            'information_course': {'count': 1, 'data': [{'id': 176, 'name': 'Основы деловой переписки'}]}
        }, {
            'id': 13,
            'name': 'Амбассадоры',
            'slug': 'ambassadory',
            'section_image': 'http://lift.teachbasetest.ru/media/course_section/None/images/2021-10-12_13-26-48.jpg',
            'in_main_page': true,
            'information_course': {'count': 1, 'data': [{'id': 173, 'name': 'Как общаться с людьми на работе?'}]}
        }, {
            'id': 12,
            'name': 'Культура и искусство',
            'slug': 'culture_and_art',
            'section_image': null,
            'in_main_page': false,
            'information_course': {
                'count': 1,
                'data': [{'id': 219, 'name': 'Тестовый для проверки всех полей материалов'}]
            }
        }, {
            'id': 17,
            'name': 'Менеджмент',
            'slug': 'menedzhment',
            'section_image': 'http://lift.teachbasetest.ru/media/course_section/None/images/2021-10-12_13-27-42.jpg',
            'in_main_page': true,
            'information_course': {
                'count': 1,
                'data': [{'id': 172, 'name': 'Трек "Агропром" для школьников (профориентационная студия)'}]
            }
        }, {
            'id': 15,
            'name': 'Профориентация',
            'slug': 'proforientatsija',
            'section_image': 'http://lift.teachbasetest.ru/media/course_section/None/images/2021-10-12_13-27-15.jpg',
            'in_main_page': true,
            'information_course': {
                'count': 2,
                'data': [{'id': 171, 'name': 'Противодействие жёстким переговорам'}, {
                    'id': 196,
                    'name': 'Как ставить умные цели'
                }]
            }
        }, {
            'id': 18,
            'name': 'Эффективность',
            'slug': 'effektivnost',
            'section_image': 'http://lift.teachbasetest.ru/media/course_section/None/images/2021-10-12_13-27-55.jpg',
            'in_main_page': true,
            'information_course': {
                'count': 7,
                'data': [{'id': 165, 'name': 'Трек "Леспром" (профориентационная студия)'}, {
                    'id': 166,
                    'name': 'Довольный клиент: как общаться и не прогибаться?'
                }, {'id': 167, 'name': 'Как создать  wow-презентацию (ч.2). Структура и дизайн'}, {
                    'id': 169,
                    'name': 'Как мотивировать коллег?'
                }, {'id': 170, 'name': 'Как объяснить бабушке, что такое Agile'}, {
                    'id': 174,
                    'name': 'Как давать своевременную и развивающую обратную связь?'
                }, {'id': 195, 'name': 'Excel. Основы'}]
            }
        }],
        filterSubSections: [{'id': 15, 'name': 'ИТ', 'number_of_courses': 2}, {
            'id': 7,
            'name': 'Личная эффективность',
            'number_of_courses': 3
        }, {'id': 2, 'name': 'Новому сотруднику', 'number_of_courses': 1}, {
            'id': 4,
            'name': 'Переговоры',
            'number_of_courses': 1
        }, {'id': 5, 'name': 'Презентации', 'number_of_courses': 3}, {
            'id': 16,
            'name': 'Программы для ПК',
            'number_of_courses': 1
        }, {'id': 6, 'name': 'Психология', 'number_of_courses': 3}, {
            'id': 1,
            'name': 'Собеседование',
            'number_of_courses': 1
        }],
    }),
    created() {
        let counter = 1980;
        while (counter <= 2021) {
            this.options.years.push(counter++);
        }
    },
    methods: {
        ...mapActions('user', [
            'loginExternal'
        ]),
        setName(val) {
            this.name = val
        },
        selectCity(val) {
            this.city = val;
        },
        resetCity() {
            this.isClearCity = true;
        },
        keyDownSalary(event) {
            //Backspace
            if (event.keyCode === 8 || event.key === 'Backspace') {
                if (event.target.selectionStart === this.salary.length) {
                    let [salary, sufix] = this.salary.split(' ');
                    this.salary = `${salary.substr(
                        0,
                        salary.length - 1
                    )} ${sufix}`;
                    console.log(this.salary);
                }
            }
        },
        selectEducation(value) {
            this.educationName = value;
        },
        changeFilters(parametersFilter) {
            this.filters = parametersFilter;
            console.log('this.filters', this.filters);
        },
        clearFilter(parametersFilter) {
            this.filters = parametersFilter;
            console.log('this.filters', this.filters);
        }
    },
    validations: {
        education: {
            required
        }
    }
};
</script>

<style lang="less">
.container-checkboxs {
    display: flex;
    flex-direction: column;

    label {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.content {
    max-width: 1400px;
}

.container {
    padding: 10px;
    background: #8e7c75;

    button {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .container-soial {
        margin-bottom: 10px;
    }

    button {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .container-soial {
        margin-bottom: 10px;
    }
}

.button-save {
    &__icon {
        margin-right: 15px;
        position: relative;
        background: url(../../assets/img/icons/icon-download.svg) center center no-repeat;
        width: 20px;
        height: 20px;
    }

    &:hover {
        .button-save__icon {
            background: url(../../assets/img/icons/icon-download-white.svg) center center no-repeat;
        }
    }
}

.button-download {
    span {
        color: #999;
        border-bottom: 1px dashed black;
    }

    &__icon {
        margin-right: 12px;
    }

    &:hover {
        opacity: 0.7;
    }
}
</style>
